import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { processingStatus } from '@pages/searchResult/redux/processingStatus';
import { ProcessingStatus } from '@pages/searchResult/redux/state';
import { resetToInitial } from '@pages/searchResult/redux/resetToInitial';

import { Filters } from '../filters';
import { httpCreateSearch } from './createSearch.http';

interface Response {
  channel_id: string;
  rsearch_id: number;
}

export class CreateSearchThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  private response: Response | undefined;

  async invoke() {
    const filters = new Filters();
    const { search } = this.getState();
    if (!search.query.blocksList.length) {
      return;
    }

    this.resetToInitial();

    sendAmplitudeData('search documents');
    Userpilot.track('search documents');

    try {
      this.dispatchProcessing();
      filters.createFilters(search.query);
      const conditions = filters.undecorate();
      const requestParams = this.buildRequestParams(conditions);
      this.response = await httpCreateSearch(requestParams);
      this.dispatchProcessingDone();
      this.redirectToDocuments();
    } catch (error) {
      throw Error(error.status);
    }
  }

  private resetToInitial() {
    const state = this.getState();
    const { id } = state.searchResult;

    if (!id) {
      return;
    }

    this.dispatch(resetToInitial.createAction());
  }

  private buildRequestParams(conditions) {
    const smartFilter = this.getState().search.query.smartFilter;
    if (!smartFilter.query) {
      return {
        conditions,
      };
    }

    return {
      conditions,
      wizard_filters: {
        query: smartFilter.query,
        is_changed: smartFilter.hasChange,
      },
      should_save: true,
    };
  }

  private dispatchProcessing() {
    this.dispatch(processingStatus.createAction(ProcessingStatus.processing));
  }

  private dispatchProcessingDone() {
    this.dispatch(processingStatus.createAction(ProcessingStatus.done));
  }

  private redirectToDocuments() {
    // @ts-ignore
    window.location.href = `#/search/${this.response.search_id}/documents`;
  }

}

export function createSearch(dispatch) {
  const thunk = new CreateSearchThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke();

}
