import React from 'react';

import s from './tooltipParticipant.style';

interface Props {
  title: string;
  data: any;
  onClick:(data: any) => void;
}

export const ParticiantItem: React.FC<Props> = (props) => {

  const handleClick = () => {
    props.onClick(props.data);
  };

  return (
    <s.ParticiantItem
      onClick={handleClick}
    >
      {props.title}
    </s.ParticiantItem>
  );
};

export default ParticiantItem;
