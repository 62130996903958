import { connect } from 'react-redux';

import { createSearchThunk } from '../redux/createSearchThunk';
import { SearchLaw } from './components/searchLaw';

const mapStateToProps = (state) => ({
  documentId: state.lawDocument.documentId,
});

const mapDispatchToProps = (dispatch) => ({
  onSelect(conditions) {
    createSearchThunk(dispatch, conditions);
  },
});

export const SearchLawConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchLaw);

export default SearchLawConnect;
