import { TooltipRelationConnect } from './tooltipRelations.connect';
import { TooltipParticipantConnect } from './tooltipParticipant.connect';
import { TooltipReasonConnect } from './tooltipReasons.connect';

export enum TooltipType {
  relation = 'relation',
  participant = 'participant',
  reason = 'reason',
}

export const tooltipsMap = new Map();

tooltipsMap.set(TooltipType.relation, TooltipRelationConnect);
tooltipsMap.set(TooltipType.participant, TooltipParticipantConnect);
tooltipsMap.set(TooltipType.reason, TooltipReasonConnect);

export default tooltipsMap;
