import { connect } from 'react-redux';

import { httpFetchRelationParticipant } from '@pages/document/sidebar/redux/fetchRelationParticipant.http';

import { addParticipantBlockThunk } from '@pages/document/redux/addParticipantBlock.thunk';

import { TooltipParticipant } from './tooltipParticipant';

const mapDispatchToProps = (dispatch, ownProps) => ({
  async onFetchParticipants() {
    const items = await httpFetchRelationParticipant({ data: ownProps.sourceId });

    return {
      items,
    };
  },

  handleItemClick(data) {
    addParticipantBlockThunk(dispatch, data);
  },

});

export const TooltipParticipantConnect = connect(
  null,
  mapDispatchToProps,
)(TooltipParticipant);

export default TooltipParticipantConnect;
