import React from 'react';

import { Button } from '@pages/searchResult/components/button/button';

import { GlobalSettings } from './globalSettings';

import s from './header.style';

export interface HeaderProps {
  disable: boolean,
  onDownload(): void;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.TopHeader>
        <s.Title>
          Заметки
        </s.Title>
        <s.Actions>
          <s.ActionIcon>
            <Button
              title="Скачать заметки в PDF"
              disabled={this.props.disable}
              onClick={this.props.onDownload}
            />
          </s.ActionIcon>
          <s.OptionsKebab>
            <GlobalSettings disabled={this.props.disable} />
          </s.OptionsKebab>
        </s.Actions>
      </s.TopHeader>
    );
  }

}

export default Header;
