import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  position: fixed;
  opacity: 0;
  text-wrap-mode: nowrap;
  font-size: 11px;
  line-height: 16px;
  font-family: Roboto-Medium;
  color: ${(props: Props) => props.theme.colors.white};
  background: rgba(85, 99, 112, 0.9);
  padding: 4px 8px;
  border-radius: 2px;
  z-index: 9999;
`;

export default style;
