import {
  LawBlock, LawBlockData,
} from '../../form/filterBlocks/law/redux/law';

export abstract class LawBlockHistory extends LawBlock {

  static transform(data: LawBlockData): string[] {
    const documentTitle = data.document ? data.document.title : '';
    const articleTitle = data.article ? data.article.title : '';
    const clauseTitle = data.clause ? data.clause.title : '';
    const arrOfTitles = [documentTitle, articleTitle, clauseTitle];

    const result = arrOfTitles.reduce((accumulator: string[], item: string, currentIndex) => {
      let currentItem = '';
      if (currentIndex) {
        currentItem = ' • ';
      }

      if (item) {
        accumulator.push(`${currentItem}${item}`);
      }

      return accumulator;
    }, []);

    return result;
  }

}

export default LawBlockHistory;
