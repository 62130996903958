import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Loader } from '@components/loader';

import { TooltipRelationsTitle } from './tooltipRelationsTitle';
import { TooltipRelationsArticles } from './tooltipRelationsArticles';
import { TooltipRelationsClauses } from './tooltipRelationsClauses';

import s from './tooltipRelations.style';

interface Props {
  onFetchRelation:() => Promise<any>;
}

export class TooltipRelations extends React.PureComponent<Props> {

  state = {
    data: {
      document: null,
      articles: [],
      clauses: [],
    },
    loading: true,
  };

  componentDidMount() {
    sendAmplitudeData('click law link in text', { source: 'document' });
    Userpilot.track('click law link in text', { source: 'document' });
    this.fetchRelation();
  }

  fetchRelation = async () => {
    const data = await this.props.onFetchRelation();
    this.setState({
      data,
      loading: false,
    });
  };

  handleLawClick = () => {
    sendAmplitudeData('click law link in pop-up', { source: 'document' });
    Userpilot.track('click law link in pop-up', { source: 'document' });
  };

  renderArticles() {
    if (isEmpty(this.state.data.articles)) {
      return null;
    }

    return (
      <TooltipRelationsArticles
        onClick={this.handleLawClick}
        items={this.state.data.articles}
      />
    );
  }

  renderClauses() {
    if (isEmpty(this.state.data.clauses)) {
      return null;
    }

    return (
      <TooltipRelationsClauses
        onClick={this.handleLawClick}
        items={this.state.data.clauses}
      />
    );
  }

  renderTooltip() {
    if (this.state.loading) {
      return (
        <s.Loader>
          <Loader viewMode size={32} />
        </s.Loader>
      );
    }

    return (
      <>
        <TooltipRelationsTitle
          onClick={this.handleLawClick}
          {...this.state.data.document}
        />
        <s.Scrollable>
          {this.renderArticles()}
          {this.renderClauses()}
        </s.Scrollable>
      </>
    );
  }

  render() {
    return (
      <s.Root
        className="relations-tooltip"
      >
        {this.renderTooltip()}
      </s.Root>
    );
  }

}

export default TooltipRelations;
