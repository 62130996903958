import { styled } from '@components/styled';

const style: any = {};

interface Props {
  top: number,
  left: number,
}

style.Root = styled.div`
  position: absolute;
  top: ${(props: Props) => props.top}px;
  left: ${(props: Props) => props.left}px;
  z-index: 10;
`;

export default style;
