import React from 'react';

import { Loader } from '@components/loader';

import { DocumentBody } from '@pages/lawDocument/workspace/documentBody';
import { BlockProps } from '@pages/lawDocument/workspace/block';

import s from './document.style';

interface DocumentTabProps {
  body: BlockProps[];
  loading: boolean;
}

export class Document extends React.PureComponent<DocumentTabProps> {

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    const targetId = 'law-document-view-container';
    const onBodyMounted = () => {};

    return (
      <s.Root
        id={targetId}
      >
        <s.Wrapper>
          <DocumentBody
            isPreview
            bodyBlocks={this.props.body}
            onBodyMounted={onBodyMounted}
          />
        </s.Wrapper>
      </s.Root>
    );
  }

}

export default Document;
