import React from 'react';
import {
  IconButton,
  Tooltip,
} from '.';

import s from './iconButtonWithTooltip.style';

export interface IconButtonWithTooltipProps {
  tooltip: string;
  iconName: string;
}

export const IconButtonWithTooltip = (props: IconButtonWithTooltipProps) => {
  const buttonRef = React.useRef();

  return (
    <s.Root>
      <IconButton targetRef={buttonRef} iconName={props.iconName} {...props} />
      <Tooltip targetRef={buttonRef} tooltip={props.tooltip} />
    </s.Root>
  );
};

export default IconButtonWithTooltip;
