import styled from 'styled-components';

import { adaptive } from '@pages/document/workspace/workspace.style';

const style: any = {};

style.Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  margin: 0 auto;

  min-width: 448px;

  ${adaptive()}
`;

style.View = styled.div``;

style.Loader = styled.div`
  height: 100%;
  position: relative;
`;

style.Header = styled.div`
  font-family: Roboto-Bold;
  text-align: center;
  padding: 3px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

style.Crest = styled.div`
  width: 72px;
  height: 72px;
`;

style.Title = styled.h4`
  padding-top: 9px;
  font-family: Roboto-Regular;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
`;

style.Type = styled.h5`
  padding-top: 9px;
  font-family: Roboto-Bold;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
`;

export default style;
