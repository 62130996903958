import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { HeaderConnect as Header } from './header/header.connect';

import { SidebarConnect as Sidebar } from './sidebar/sidebar.connect';
import { WorkspaceConnect as Workspace } from './workspace/workspace.connect';

import s from './subscriptions.style';

interface SubscriptionsProps {
  onInit(): void;

  onResetToInitial(): void;
}

export class Subscriptions extends React.PureComponent<SubscriptionsProps> {

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('subscription view');
    Userpilot.track('subscription view');
    this.props.onResetToInitial();
    this.props.onInit();
  }

  componentWillUnmount() {
    this.props.onResetToInitial();
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <Header />
          <s.Content>
            <Sidebar />
            <Workspace />
          </s.Content>
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Subscriptions;
