import { Dispatch } from 'redux';

import { cleanForm } from '@pages/search/form/redux/cleanForm';
import { addBlock } from '@pages/search/form/redux/addBlock';

import { normalizeType } from '../../search/form/utils/blocksTypesMap';

export class AddParticipantBlockThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public invoke(data) {
    this.cleanForm();

    const type = normalizeType(data.type);
    this.addParticipantBlock(type, data.data);

    this.redirectToSearch();
  }

  private addParticipantBlock(type, data) {
    const presets = this.getState().search.presets.participantModes;
    const filterPresets = presets.find((item) => item.id === data.mode.id);

    const defaultData = {
      mode: {
        id: filterPresets.id || null,
        title: filterPresets.title || null,
      },
      participant: {
        id: data.participant.id || null,
        title: data.participant.title || '',
      },
    };

    this.dispatch(addBlock.createAction({ type, defaultData }));
  }

  private cleanForm() {
    this.dispatch(cleanForm.createAction());
  }

  private redirectToSearch() {
    window.location.href = '#/search/?no_fetch_filters=true';
  }

}

export function addParticipantBlockThunk(dispatch, data) {
  const thunk = new AddParticipantBlockThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(data);
}

export default addParticipantBlockThunk;
