import {
  useState,
  useEffect,
} from 'react';

import { httpFetchLawFilters } from '../../redux/fetchLawFilters.http';

export const useFetch = (props) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await httpFetchLawFilters(props.documentId, props.articleId);
        setIsLoading(false);
        setItems(response);
      } catch (error) {
        throw Error(error.status);
      }
    };

    fetchData();
  }, []);

  return { items, isLoading };
};

export default useFetch;
