import React from 'react';

import { ReasonItem } from './tooltipReasonItem';

import s from './tooltipReasons.style';

interface ReasonsItem {
  description: string;
  titles: string[];
}

interface Props {
  reasonClaimant: ReasonsItem;
  reasonDefendant: ReasonsItem;
}

export class TooltipReasons extends React.PureComponent<Props> {

  renderTooltip() {
    return (
      <>
        <ReasonItem
          reason={this.props.reasonClaimant}
        />
        <ReasonItem
          reason={this.props.reasonDefendant}
        />
      </>
    );
  }

  render() {
    return (
      <s.Root
        className="reasons-tooltip"
      >
        {this.renderTooltip()}
      </s.Root>
    );
  }

}

export default TooltipReasons;
