import React from 'react';

import { ListItem } from '@components/menu/listItem';

import { RemoveAllMenu } from './removeAllMenu';

import s from './globalMenu.style';

interface MainLevel {
  title: string
  onClick: () => void
  hasArrow: boolean
}

export type PackageId = number;

interface Props {
  id: PackageId;
  onDownload: () => void;
  onRemoveAll: () => void;
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  closePopover: () => void;
}

enum ViewTypes {
  download = 'DOWNLOAD',
  remove = 'REMOVE',
  default = '',
}

enum Level {
  main,
  edit,
}

export class GlobalMenu extends React.PureComponent<Props> {

  state = {
    view: {
      type: ViewTypes.default,
      level: Level.main,
    },
  };

  setView(type: ViewTypes, level: number) {
    this.setState({
      view: {
        type,
        level,
      },
    });
  }

  handleSetViewToMain = () => {
    this.props.onLevelChange({ wide: false, level: Level.main });
    this.setView(ViewTypes.default, Level.main);
  };

  handleDownload = () => {
    this.props.onDownload();
    this.props.closePopover();
  };

  handleSetRemoveLevelToModify = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.remove, Level.edit);
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  renderMainLevel(renderData: MainLevel) {
    const firstLevel = this.state.view.level === Level.main;

    if (!firstLevel) {
      return null;
    }

    return <ListItem {...renderData} />;
  }

  renderMainRemove() {
    const data = {
      title: 'Удалить заметки',
      onClick: this.handleSetRemoveLevelToModify,
      hasArrow: true,
    };

    return this.renderMainLevel(data);
  }

  renderMainDownload() {
    const data = {
      title: 'Скачать заметки в PDF',
      onClick: this.handleDownload,
      hasArrow: false,
    };

    return this.renderMainLevel(data);
  }

  renderModifyRemoveLevel() {
    return (
      <RemoveAllMenu
        onGoBack={this.handleSetViewToMain}
        onSetViewToMain={this.handleSetViewToMain}
        onClosePopover={this.props.closePopover}
        onRemoveAll={this.props.onRemoveAll}
      />
    );
  }

  renderSecondLevel() {
    const isRemove = this.state.view.type === ViewTypes.remove;
    if (isRemove) {
      return this.renderModifyRemoveLevel();
    }

    return null;
  }

  renderLevels() {
    const firstLevel = this.state.view.level === Level.main;
    if (firstLevel) {
      return (
        <s.List>
          {this.renderMainDownload()}
          {this.renderMainRemove()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {this.renderSecondLevel()}
      </s.Edit>
    );
  }

  render() {
    return (
      <s.Root onClick={this.stopPropagation}>
        {this.renderLevels()}
      </s.Root>
    );
  }

}

export default GlobalMenu;
