import React from 'react';

import { withClickOutside } from './clickOutsideHandler';
import { positionBuilder } from './utils';

import s from './tooltip.style';

interface TooltipProps {
  element: HTMLElement;
  children: React.ReactNode;
}

interface StateProps {
  positionTop: number,
  positionLeft: number,
}

export class TooltipWpapper extends React.PureComponent<TooltipProps, StateProps> {

  refRoot = React.createRef();

  state = {
    positionTop: 0,
    positionLeft: 0,
  };

  constructor(props) {
    super(props);

    const callback = () => {
      this.setPosition();
    };

    // @ts-ignore
    this.observer = new MutationObserver(callback);
  }

  componentDidMount() {
    this.setPosition();
    this.setObserver();
  }

  componentWillUnmount() {
    // @ts-ignore
    this.observer.disconnect();
  }

  setObserver = () => {
    const target = this.refRoot.current;
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    // @ts-ignore
    this.observer.observe(target, config);
  };

  setPosition() {
    const reference = this.refRoot.current;
    if (!reference) {
      return;
    }

    const { positionTop, positionLeft } = positionBuilder(this.props.element, reference);

    this.setState({
      positionTop,
      positionLeft,
    });
  }

  render() {
    return (
      <s.Root
        ref={this.refRoot}
        top={this.state.positionTop}
        left={this.state.positionLeft}
      >
        {this.props.children}
      </s.Root>
    );
  }

}

export const Tooltip = withClickOutside(TooltipWpapper);

export default Tooltip;
