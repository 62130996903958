import styled from 'styled-components';

const style = {};

style.Root = styled.div`
  min-height: 56px;
  max-height: 320px;
  flex-direction: column;
  width: 320px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

style.Tooltip = styled.div`
  width: 100%;
`;

style.Title = styled.div`
  color: black;
  font-family: Roboto-Medium;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  padding: 16px 44px;
  border-bottom: 1px solid #dfe2e6;
`;

style.ParticiantItem = styled.div`
  color: #22313f;
  height: 40px;
  padding: 12px 16px;
  line-height: normal;
  font-weight: normal;
  font-family: Roboto-Regular;
  cursor: pointer;
  
  &:hover {
    background-color: #f9f9f9;
  }
`;

style.ItemsWrapper = styled.div`
  padding: 10px 0;
`;

style.Loader = styled.div`
  > div {
    border-color: #fff #fff #4183d7;
  }
`;

export default style;
