import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './listItem.style';

function renderArrowRight() {
  const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
  const points = iconsPath.get('arrowRight');

  return (
    <s.Arrow>
      <Icon
        points={points}
        transform={transform}
        color="currentColor"
      />
    </s.Arrow>
  );
}

interface Props {
  title: string;
  disable?: boolean;
  hasArrow?: boolean;
  onClick: () => void;
}

export const ListItem = (props: Props) => {
  const renderArrow = () => {
    if (!props.hasArrow) {
      return null;
    }

    return renderArrowRight();
  };

  const handleClick = () => {
    if (props.disable) {
      return;
    }

    props.onClick();
  };

  return (
    <s.Root
      onClick={handleClick}
    >
      <s.Title disable={props.disable}>{props.title}</s.Title>
      {renderArrow()}
    </s.Root>
  );
};

export default ListItem;
