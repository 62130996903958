import {
  styled,
  css,
} from '@components/styled';

const style: any = {};

interface Props {
  activeReason: boolean;
  theme: any;
}

style.Root = styled.div`
  display: flex;
  flex-direction: column;
`;

style.MarkersRoot = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

style.Item = styled.div`
  line-height: 24px;

  a {
    cursor: default;
    pointer-events: none;
    text-decoration: none;
    color: ${(props) => props.theme.colors.defaultText};
    font-family: Roboto-Bold;
  }

  .g-highlight {
    background: rgba(255, 190, 37, 0.5);
  }
`;

style.ResolutionItem = styled.div`
  line-height: 24px;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.defaultText};
    font-family: Roboto-Bold;
  }

  .g-highlight {
    background: rgba(255, 190, 37, 0.5);
  }

  .mf-relations-links, .mf-relations-participant {
    cursor: pointer;
    color: #4183d7;
    text-decoration: none;
    outline: none;
    font-family: Roboto-Regular;
  }
`;

style.Wrapper = styled.div`
  position: relative;
  padding: 0 64px;
`;

style.Reason = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 51px;
  border-radius: 1px;
  transition: .2s;

  ${(props: Props) => props.activeReason && css`
    background: ${props.theme.colors.blue};
  `}
`;

style.Meta = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
`;

style.Crest = styled.div`
  width: 72px;
  height: 72px;
`;

style.Title = styled.h4`
  padding-top: 25px;
  font-family: Roboto-Bold;
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
`;

style.Department = styled.div`
  font-family: Roboto-Bold;
  font-size: 13px;
  line-height: 24px;
  padding-top: 16px;
`;

style.LastDivider = styled.div`
  diplay: flex;
  flex: 1;
  min-height: 86px;
  border-top: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: ${(props) => props.theme.colors.backgroundGrey};
`;

export default style;
