import React from 'react';

import {
  Block,
  BlockProps,
} from './block';

import s from './documentBody.style';

export interface DocumentBodyProps {
  isPreview: boolean;
  bodyBlocks: BlockProps[];

  onBodyMounted(): void;
}

export class DocumentBody extends React.PureComponent<DocumentBodyProps> {

  componentDidMount() {
    this.setMounted();
  }

  setMounted = () => {
    window.setTimeout(() => {
      this.props.onBodyMounted();
    }, 300);
  };

  render() {
    const shouldRenderAction = !this.props.isPreview;

    return (
      <s.Root>
        {this.props.bodyBlocks.map((block) => (
          <Block
            shouldRenderAction={shouldRenderAction}
            key={block.id}
            {...block}
          />
        ))}
      </s.Root>
    );
  }

}

export default DocumentBody;
