import React from 'react';

import { Loader } from '@components/loader';

import { ParticiantItem } from './tooltipParticipantItem';

import s from './tooltipParticipant.style';

interface Props {
  onFetchParticipants:() => Promise<any>;
  handleItemClick:(data: any) => void;
}

export class TooltipParticipant extends React.PureComponent<Props> {

  state = {
    items: [],
    loading: true,
  };

  componentDidMount() {
    this.fetchSimilarParticiant();
  }

  fetchSimilarParticiant = async () => {
    const { items } = await this.props.onFetchParticipants();

    this.setState({
      items,
      loading: false,
    });
  };

  renderItem = (item) => (
    <ParticiantItem
      title={item.title}
      data={item.conditions[0]}
      onClick={this.props.handleItemClick}
    />
  );

  renderTooltip() {
    if (this.state.loading) {
      return (
        <s.Loader>
          <Loader viewMode size={32} />
        </s.Loader>
      );
    }

    return (
      <s.Tooltip>
        <s.Title>
          Поиск практики по участнику
        </s.Title>
        <s.ItemsWrapper>
          {this.state.items.map(this.renderItem)}
        </s.ItemsWrapper>
      </s.Tooltip>
    );
  }

  render() {
    return (
      <s.Root
        className="participant-tooltip"
      >
        {this.renderTooltip()}
      </s.Root>
    );
  }

}

export default TooltipParticipant;
