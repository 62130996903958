import { connect } from 'react-redux';

import { httpFetchRelation } from '../../sidebar/redux/fetchRelation.http';

import { TooltipRelations } from './tooltipRelations';

const mapDispatchToProps = (dispatch, ownProps) => ({
  async onFetchRelation() {
    const { relatedEntities } = await httpFetchRelation({ relationId: ownProps.sourceId });

    return {
      document: relatedEntities.document,
      articles: relatedEntities.articles,
      clauses: relatedEntities.clauses,
    };
  },
});

export const TooltipRelationConnect = connect(
  null,
  mapDispatchToProps,
)(TooltipRelations);

export default TooltipRelationConnect;
