import React from 'react';

import { BlockType } from '../../components/blockType/blockType';
import { Contains } from '../../components/contains/contains';
import { Suggest } from '../../components/suggest/suggest';

import { LawBlock as Block } from './redux/law';

import { Data as DataDocument } from './redux/updateDocument';
import { Data as DataArticle } from './redux/updateArticle';
import { Data as DataClause } from './redux/updateClause';

import s from './block.style';

export interface LawBlockProps {
  // eslint-disable-next-line react/no-unused-prop-types
  roles: any;
  item: Block;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeBlockType: (data: any) => void;
  onChangeContains: (data: any) => void;
  onUpdateDocument: (data: DataDocument) => void;
  onUpdateArticle: (data: DataArticle) => void;
  onUpdateClause: (data: DataClause) => void;
}

export class LawBlock extends React.PureComponent<LawBlockProps> {

  handleChangeContains = (contains) => {
    const id = this.props.item.getId();
    const data = {
      id,
      contains,
    };

    this.props.onChangeContains(data);
  };

  handleChangeDocument = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateDocument(actualData);
  };

  handleGetLaw = async (query) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = await this.props.item.getItemsForLawSuggest(query);

    return result;
  };

  handleChangeArticle = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateArticle(actualData);
  };

  handleGetArticles = async (query) => {
    const data = this.props.item.getData();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = await this.props.item.getItemsForArticleSuggest(
      query,
      data.document.id,
    );

    return result;
  };

  handleGetClauses = async (query) => {
    const item = this.props.item.getData();
    const documentId = item.document.id;

    const isOnlyClause = !item.document.hasChildren && item.article.hasChildren;
    const articleId = isOnlyClause ? '' : item.article.id;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = await this.props.item.getItemsForClauseSuggest(query, documentId, articleId);

    return result;
  };

  handleChangeClause = (data) => {
    const id = this.props.item.getId();
    const actualData = {
      id,
      data,
    };

    this.props.onUpdateClause(actualData);
  };

  renderBlockType() {
    const type = this.props.item.getType();

    return (
      <s.FilterItem size="176px">
        <BlockType
          title={type.title}
        />
      </s.FilterItem>
    );
  }

  renderContains() {
    const contains = this.props.item.getContains();

    return (
      <s.FilterItem size="176px">
        <Contains
          contains={contains}
          onChange={this.handleChangeContains}
        />
      </s.FilterItem>
    );
  }

  renderDocument() {
    const data = this.props.item.getData();
    const document = {
      id: data.document.id,
      title: data.document.title,
    };

    return (
      <s.FilterItem isGrow>
        <Suggest
          width="480px"
          activeItem={document}
          placeholder="Норма права"
          getItems={this.handleGetLaw}
          onChange={this.handleChangeDocument}
        />
      </s.FilterItem>
    );
  }

  renderArticle() {
    const data = this.props.item.getData();
    const hasArticle = data.document.hasChildren;
    const article = {
      id: null,
      title: '',
    };

    if (hasArticle) {
      article.id = data.article.id;
      article.title = data.article.title;
    }

    return (
      <s.FilterItem size="144px">
        <Suggest
          width="480px"
          activeItem={article}
          disabled={!hasArticle}
          placeholder="Статья"
          getItems={this.handleGetArticles}
          onChange={this.handleChangeArticle}
        />
      </s.FilterItem>
    );
  }

  renderClause() {
    const data = this.props.item.getData();
    const clause = {
      id: null,
      title: '',
    };

    const hasClause = data.article && data.article.hasChildren;
    if (hasClause) {
      clause.id = data.clause.id;
      clause.title = data.clause.title;
    }

    return (
      <s.FilterItem size="144px">
        <Suggest
          useAnchorWidth
          activeItem={clause}
          disabled={!hasClause}
          placeholder="ч., п., пп."
          getItems={this.handleGetClauses}
          onChange={this.handleChangeClause}
        />
      </s.FilterItem>
    );
  }

  render() {
    const category = this.props.item.getCategory();

    return (
      <s.FilterBlock
        color={category.color}
      >
        {this.renderBlockType()}
        {this.renderContains()}
        {this.renderDocument()}
        {this.renderArticle()}
        {this.renderClause()}
      </s.FilterBlock>
    );
  }

}

export default LawBlock;
