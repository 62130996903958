import { TooltipType } from '../../pages/document/workspace/components/tooltips.map';

export function tooltipParamsBuilder(element, isReasonBlock?) {
  const relationId =
    element.getAttribute('data-id-relation') ||
    element.parentElement.getAttribute('data-id-relation');

  const blockId =
    element.getAttribute('data-entity-id') ||
    element.parentElement.getAttribute('data-entity-id') ||
    element.closest('div').parentElement.getAttribute('data-entity-id');

  const hasRelationsLinks =
    element.classList.contains('mf-relations-links') ||
    element.parentNode.classList.contains('mf-relations-links');

  if (hasRelationsLinks) {
    return {
      tooltipType: TooltipType.relation,
      blockId,
      sourceId: relationId,
    };
  }

  const hasRelationsParticipant =
    element.classList.contains('mf-relations-participant') ||
    element.parentNode.classList.contains('mf-relations-participant');

  if (hasRelationsParticipant) {
    return {
      tooltipType: TooltipType.participant,
      blockId,
      sourceId: relationId,
    };
  }

  if (isReasonBlock) {
    return {
      tooltipType: TooltipType.reason,
      blockId,
      sourceId: blockId,
    };
  }

  return {
    tooltipType: null,
    blockId: null,
    sourceId: null,
  };
}

function offsetsBuilder(element) {
  const ChildrenBlockDomRect = element.getBoundingClientRect();
  const ContentBlockDomRect = element.closest('div').getBoundingClientRect();
  const ParentBlockDomRect = element.closest('div').parentElement.getBoundingClientRect();

  const contentIndent = ParentBlockDomRect.height - ContentBlockDomRect.height;

  const childrenBlockOffsets = {
    top: ChildrenBlockDomRect.top,
    left: ChildrenBlockDomRect.left,
    bottom: ChildrenBlockDomRect.bottom,
    width: ChildrenBlockDomRect.width,
  };

  const contentBlockOffsets = {
    top: ContentBlockDomRect.top,
    left: ContentBlockDomRect.left,
    bottom: ContentBlockDomRect.bottom,
    width: ContentBlockDomRect.width,
  };

  return {
    childrenBlockOffsets,
    contentBlockOffsets,
    contentIndent,
  };
}

export function positionBuilder(clickedElement, reference) {
  const HALF_PARENT_BLOCK_INDENT = 64;
  const DEFAULT_INDENT_UP = 5;

  const { childrenBlockOffsets, contentBlockOffsets, contentIndent } = offsetsBuilder(clickedElement);

  const middlePositionTooltip = contentBlockOffsets.width / 2 - reference.offsetWidth / 2 + HALF_PARENT_BLOCK_INDENT;

  const top = childrenBlockOffsets.top - contentBlockOffsets.top + contentIndent;
  const left = childrenBlockOffsets.left - contentBlockOffsets.left;

  let positionTop = -(reference.offsetHeight - top + DEFAULT_INDENT_UP);

  let positionLeft = left + HALF_PARENT_BLOCK_INDENT - reference.offsetWidth / 2 + childrenBlockOffsets.width / 2;
  if (positionLeft < 64) {
    positionLeft = 64;
  }

  const endRightPosition = contentBlockOffsets.width + HALF_PARENT_BLOCK_INDENT;
  if (positionLeft + reference.offsetWidth > endRightPosition) {
    positionLeft = contentBlockOffsets.width + HALF_PARENT_BLOCK_INDENT - reference.offsetWidth;
  }

  const isReasonTooltip = reference.firstChild.classList.contains('reasons-tooltip');
  if (isReasonTooltip) {
    positionTop = -reference.offsetHeight;
    positionLeft = middlePositionTooltip;
  }

  return {
    positionTop,
    positionLeft,
  };
}
