import { cleanFormReduce } from './cleanForm.reduce';
import { SEARCH_FORM_BLOCK } from '../middleware/resetActiveItemMiddleware';

const actionType = `${SEARCH_FORM_BLOCK}/CLEAN_FORM`;

const createAction = () => ({
  type: actionType,
});

export const cleanForm = {
  actionType,
  createAction,
  reduce: cleanFormReduce,
};

export default cleanForm;
