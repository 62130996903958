import React from 'react';

import { tooltipParamsBuilder } from '@components/tooltip/utils';
import { Tooltip } from '@components/tooltip/tooltip';

import { tooltipsMap } from '@pages/document/workspace/components/tooltips.map';

import { SearchLawConnect } from './searchLaw.connect';

import s from './block.style';

export interface BlockProps {
  id: string;
  shouldRenderAction: boolean;
  title: string;
  body: string;
}

export class Block extends React.PureComponent<BlockProps> {

  state = {
    openActionsPopover: false,
    hovered: false,
    showTooltip: false,
    tooltipType: '',
    sourceId: '',
    element: null,
  };

  handleMouseOver = () => {
    if (!this.props.shouldRenderAction) {
      return;
    }

    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    if (!this.props.shouldRenderAction) {
      return;
    }

    if (this.state.openActionsPopover) {
      return;
    }

    this.setState({
      hovered: false,
    });
  };

  handleSettingsMenuVisible = (visible: boolean) => {
    this.setState({
      openActionsPopover: visible,
    });
  };

  handleClickOutside = () => {
    this.setState({
      hovered: false,
      openActionsPopover: false,
    });
  };

  handleOnClick = (event) => {
    const element = event.target;

    const tooltipParams = tooltipParamsBuilder(element);
    const sourceId = tooltipParams.sourceId;
    if (!sourceId) {
      return;
    }

    event.preventDefault();
    if (!this.state.showTooltip) {
      this.setState({
        showTooltip: true,
        tooltipType: tooltipParams.tooltipType,
        sourceId: tooltipParams.sourceId,
        element,
      });
    }
  };

  hideTooltip = () => {
    if (this.state.showTooltip) {
      this.setState({
        showTooltip: false,
        tooltipType: '',
        sourceId: '',
        element: null,
      });
    }
  };

  renderTooltip() {
    if (!this.state.showTooltip) {
      return null;
    }

    const Component = tooltipsMap.get(this.state.tooltipType);
    if (!Component) {
      return null;
    }

    return (
      <Tooltip
        element={this.state.element}
        onClickOutside={this.hideTooltip}
      >
        <Component sourceId={this.state.sourceId} />
      </Tooltip>
    );
  }

  renderActions() {
    if (!this.props.shouldRenderAction) {
      return null;
    }

    if (!this.state.hovered) {
      return null;
    }

    return (
      <s.Actions className="actions">
        <SearchLawConnect
          articleId={this.props.title}
          onClickOutside={this.handleClickOutside}
          onMenuVisible={this.handleSettingsMenuVisible}
        />
      </s.Actions>
    );
  }

  renderBlock(body) {
    return {
      __html: body,
    };
  }

  render() {
    return (
      <s.Root
        data-block-id={this.props.id}
        onClick={this.handleOnClick}
      >
        {this.renderTooltip()}
        <s.TitleContainer
          hovered={this.state.hovered}
          onMouseOver={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
        >
          <s.Title
            dangerouslySetInnerHTML={this.renderBlock(this.props.title)}
          />
          {this.renderActions()}
        </s.TitleContainer>
        <s.Content
          dangerouslySetInnerHTML={this.renderBlock(this.props.body)}
        />
      </s.Root>
    );
  }

}

export default Block;
