import React, {
  useState, useEffect, useRef,
} from 'react';

import s from './tooltip.style';

interface TooptipProps {
  tooltip: string;
  targetRef: React.RefObject<HTMLDivElement>;
}

export const Tooltip = (props: TooptipProps) => {

  const tooltipRef = useRef(null);

  const [target, setTarget] = useState({
    width: 0,
    left: 0,
    top: 0,
  });

  const [current, setCurrent] = useState({
    width: 0,
    height: 0,
  });

  const buildTargetParams = () => {
    const targetElement = props.targetRef.current.getBoundingClientRect();
    const width = Math.ceil(targetElement.width);
    const left = Math.ceil(targetElement.left);
    const top = Math.ceil(targetElement.top);
    setTarget((prevState) => ({
      ...prevState,
      width,
      left,
      top,
    }));
  };

  const buildCurrentParams = () => {
    const currentElement = tooltipRef.current.getBoundingClientRect();
    const width = Math.ceil(currentElement.width);
    const height = Math.ceil(currentElement.height);
    setCurrent((prevState) => ({
      ...prevState,
      width,
      height,
    }));
  };

  const buildTooltipStyle = () => {
    const halfWidthTooltip = current.width / 2;
    const haldTargetWidth = target.width / 2;
    const left = target.left - halfWidthTooltip + haldTargetWidth;

    const TOP_MARGIN = 7;
    const top = target.top - current.height - TOP_MARGIN;

    return {
      top: `${top}px`,
      left: `${left}px`,
    };
  };

  useEffect(() => {
    buildTargetParams();
    buildCurrentParams();
  }, []);

  if (!props.tooltip) {
    return null;
  }

  const style = buildTooltipStyle();

  return (
    <s.Root
      ref={tooltipRef}
      style={style}
    >
      {props.tooltip}
    </s.Root>
  );
};

export default Tooltip;
