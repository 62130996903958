import React from 'react';

import { theme } from '@components/styled';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './iconButton.style';

interface IconButtonProps {
  isPoints?: boolean;
  isActive?: boolean;
  activeColor?: string;
  iconName: string;
  targetRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  onClick?: () => void;
}

export const IconButton = ({
  disabled = false,
  isPoints = false,
  isActive = false,
  activeColor = null,
  iconName,
  targetRef,
  onClick = () => { },
}: IconButtonProps) => {
  const enableColor = activeColor || theme.colors.stone;
  const color = disabled ? theme.colors.disabled : enableColor;
  const iconPath = iconsPath.get(iconName);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  const renderIcon = () => {
    if (isPoints) {
      return <Icon points={iconPath} color={color} />;
    }

    return <Icon path={iconPath} color={color} />;
  };

  return (
    <s.Root
      onClick={handleClick}
    >
      <s.Button
        ref={targetRef}
        isActive={isActive}
      >
        {renderIcon()}
      </s.Button>
    </s.Root>
  );
};

export default IconButton;
