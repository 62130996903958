import { ReducerBuilder } from '@redux/reducer.builder';

import { addBlock } from './addBlock';
import { duplicateBlock } from './duplicateBlock';
import { fetchDefaultFilters } from './fetchDefaultFilters';
import { fetchPinnedFilters } from './fetchPinnedFilters';
import { removeBlock } from './removeBlock';
import { resetForm } from './resetForm';
import { updatePin } from './updatePin';
import { cleanForm } from './cleanForm';

export const reducerBuilder = new ReducerBuilder([
  addBlock,
  duplicateBlock,
  fetchDefaultFilters,
  fetchPinnedFilters,
  removeBlock,
  updatePin,
  resetForm,
  cleanForm,
]);

export default reducerBuilder;
