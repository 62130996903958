import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  width: 384px;
  background: white;
`;

style.List = styled.div`
  padding: 8px 0;
`;

style.ListItem = styled.div`
`;

style.Loader = styled.div`
  width: 256px;
  height: 416px;
`;

export default style;
