import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { TextArea } from './textArea';

import s from './block.style';

interface SmartBlockProps {
  value: string;
  loading: boolean;
  onFetchFilters(value: string): void;
}

interface SmartBlockState {
  isExpanded: boolean;
}

export class SmartBlock extends React.PureComponent<SmartBlockProps, SmartBlockState> {

  private placeholder = 'AI - ассистент подготовки фильтра "Норма права"';

  state = {
    isExpanded: false,
  };

  handleExpand = () => {
    this.setState(() => ({
      isExpanded: true,
    }));

    this.marketingOpen();
  };

  handleCollapse = () => {
    this.setState(() => ({
      isExpanded: false,
    }));
  };

  handleSubmit = (value: string) => {
    this.props.onFetchFilters(value);
    this.marketingSubmit(value);
  };

  private marketingSubmit(value = '') {
    sendAmplitudeData('AI:caselook:aiAssistant:createFilters', { value, AI: 'Умный поиск / Caselook' });
    Userpilot.track('AI:caselook:aiAssistant:createFilters', { value });
  }

  private marketingOpen() {
    sendAmplitudeData('AI:caselook:aiAssistant:open', { AI: 'Умный поиск / Caselook' });
    Userpilot.track('AI:caselook:aiAssistant:open');
  }

  renderIcon() {
    const iconPath = iconsPath.get('smartWizard');

    return (
      <s.Icon>
        <Icon path={iconPath} color="#673ab7" />
      </s.Icon>
    );
  }

  renderLayout() {
    const value = this.props.value || this.placeholder;

    if (this.props.loading) {
      return (
        <s.DefaultControl
          isLoading
        >
          {this.renderIcon()}
          {value}
        </s.DefaultControl>
      );
    }

    if (!this.state.isExpanded) {
      return (
        <s.DefaultControl onClick={this.handleExpand}>
          {this.renderIcon()}
          {value}
        </s.DefaultControl>
      );
    }

    return (
      <TextArea
        onClose={this.handleCollapse}
        onSubmit={this.handleSubmit}
        value={this.props.value}
      />
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderLayout()}
      </s.Root>
    );
  }

}

export default SmartBlock;
