import React from 'react';

import { IconButtonWithTooltip } from '@components/buttons';
import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { LawList } from './lawList';

interface SearchLawProps {
  documentId: number;
  articleId: string;
  onMenuVisible: (visible: boolean) => void;
  onClickOutside(): void;
}

export const SearchLaw: React.FC<SearchLawProps> = (props) => {
  const renderInput = (p) => <IconButtonWithTooltip {...p} tooltip="Подобрать судебную практику" iconName="hammer" />;
  const renderMenu = (p) => <LawList {...p} {...props} />;

  return (
    <BaseDropdown
      placement="bottom-start"
      onMenuVisible={props.onMenuVisible}
      onClickOutside={props.onClickOutside}
      renderInput={renderInput}
      renderMenu={renderMenu}
    />
  );
};

export default SearchLaw;
