import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  > div:hover +div{
    opacity: 1;
  }
`;

export default style;
