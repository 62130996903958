import { RESTPath } from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  data: string;
}

export function httpFetchRelationParticipant(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.document.getParticipant(),
    data: requestParams,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchRelationParticipant;
