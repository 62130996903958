import http from '@http';
import { RESTPath } from '@router/path.rest';

export async function httpFetchLawFilters(documentId, articleId): Promise<any> {
  const request = http.get({
    url: RESTPath.lawDocument.getFilters(documentId, articleId),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchLawFilters;
