/* eslint-disable */
import React from 'react';

import { AddTagButton } from '@components/addTag/addTagButton';

import { Tooltip } from '@components/tooltip/tooltip';
import { tooltipParamsBuilder } from '@components/tooltip/utils';

import { tooltipsMap } from './components/tooltips.map';

import { theme } from '@components/styled';

import s from './block.style';

export class Block extends React.PureComponent {

  state = {
    showTooltip: false,
    tooltipType: '',
    sourceId: '',
    element: null,
  }

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.highlightReason();
  }

  componentDidUpdate() {
    this.highlightReason();
  }

  highlightReason = () => {
    let reasonElements = document.querySelectorAll('.reason');
    if (this.props.activeReason) {
      reasonElements.forEach(element => element.style = `background: ${theme.colors.blue}`);
    }

    if (!this.props.activeReason) {
      reasonElements.forEach(element => element.style = `background: ${theme.colors.white}`);
    }
  }

  createArticleMarkup() {
    return {
      __html: this.props.block.body,
    };
  }

  handleClick = () => {
    if (this.props.paragraphInBookmarks) {
      this.props.onRemoveBookmark()
      return;
    }

    const text = this.contentRef.current.innerText;

    this.props.onAddBookmark(text);
  }

  renderBookmark = () => {
    const isRestrictedDocument = this.props.isSimilarDocument || this.props.isChronologyDocument;
    if (isRestrictedDocument) {
      return null;
    }

    if (!this.props.isReal) {
      return null;
    }

    if(!this.props.paragraphInBookmarks) {
      return (
        <AddTagButton
          renderFromRight={this.props.isPreview}
          path="bookmarkEmpty"
          color="#979797"
        />
      )
    }

    return (
      <AddTagButton
        renderFromRight={this.props.isPreview}
        onAddCommentary={this.props.onAddCommentary}
        commentary={this.props.commentary}
        shouldRenderComment
        path="bookmark"
        color="#e74c3c"
      />
    )
  }

  renderReason() {
    if (!this.props.paragraphInReasons) {
      return null;
    }

    if (!this.props.activeReason) {
      return null;
    }

    return (
      <s.Reason className="reason" />
    )
  }

  renderTooltip() {
    if (!this.state.showTooltip) {
      return null;
    }

    const Component = tooltipsMap.get(this.state.tooltipType);

    return (
      <Tooltip
        element={this.state.element}
        onClickOutside={this.hideTooltip}
      >
        <Component sourceId={this.state.sourceId} />
      </Tooltip>
    )
  }

  handleOnClick = (event) => {
    if (!this.props.isReal) {
      event.preventDefault();

      return;
    }
    
    const element = event.target;

    const isReasonBlock = this.props.paragraphInReasons && this.props.activeReason;
    const tooltipParams = tooltipParamsBuilder(element, isReasonBlock);
    const sourceId = tooltipParams.sourceId;
    if (!sourceId) {
      return;
    }

    event.preventDefault();
    if (!this.state.showTooltip) {
      this.setState({
        showTooltip: true,
        tooltipType: tooltipParams.tooltipType,
        sourceId: tooltipParams.sourceId,
        element,
      })
    }
  }

  hideTooltip = () => {
    this.setState({
      showTooltip: false,
      tooltipType: '',
      sourceId: '',
      element: null,
    });
  };

  render() {
    return (
      <s.Root
        data-entity-id={this.props.block.id}
        className="article"
      >
        {this.renderReason()}
        {this.renderTooltip()}
        <s.Content
          ref={this.contentRef}
          hightlight={this.state.hovered}
          dangerouslySetInnerHTML={this.createArticleMarkup()}
          onClick={this.handleOnClick}
        />
        <s.Bookmark
          className="caselook__bookmark"
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          shouldAlwaysRender={this.props.paragraphInBookmarks}
          onClick={this.handleClick}
        >
          {this.renderBookmark()}
        </s.Bookmark>
      </s.Root>
    );
  }

}

export default Block;
