import { connect } from 'react-redux';

import { LicenseType } from '@router/pathPermissions';
import { resetDocuments } from '@pages/searchResult/documents/redux/resetDocuments';

import { resetChronologyDocuments } from '@layouts/chronology/redux/resetChronologyDocuments';

import { resetToInitial as resetSearch } from '@pages/search/redux/resetToInitial';
import { resetToInitial as resetHistory } from '@pages/search/history/redux/resetToInitial';

import { fetchFiltersFromCbTokenThunk } from './form/redux/fetchFiltersFromCbToken.thunk';
import { resetForm } from './form/redux/resetForm';

import { Search } from './search';

const mapStateToProps = (state, ownProps) => {
  const params = new URLSearchParams(ownProps.location.search);
  const formToken = params.get('cb_guid');
  const noFetchFilters = !!params.get('no_fetch_filters');
  const renderSmartFilters = state.currentUser.accessTo.includes('caselook_pro');

  const accessTo = state.currentUser.accessTo;

  const caselookLicense = accessTo.includes(LicenseType.caselook);
  const monitoringLicense = accessTo.includes(LicenseType.monitoring);
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    renderNavigation: !!multiLicense,
    formToken,
    noFetchFilters,
    renderSmartFilters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onResetSearchResult() {
    dispatch(resetDocuments.createAction());
    dispatch(resetChronologyDocuments.createAction());
  },
  onResetSearch() {
    dispatch(resetSearch.createAction());
    dispatch(resetHistory.createAction());
  },
  onResetForm() {
    dispatch(resetForm.createAction());
  },
  onFetchForm(token) {
    fetchFiltersFromCbTokenThunk(dispatch, token);
  },
});

export const SearchConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);

export default SearchConnect;
