import styled, { css } from 'styled-components';

interface Props {
  hovered: boolean;
}

const style: any = {};

style.Root = styled.div`
  position: relative;
  padding: 0 64px;
  margin: 24px 0;
`;

style.TitleContainer = styled.div`
  position: relative;
  background: transparent;
  margin-left: -64px;
  width: calc(100% + 128px);

  ${(props: Props) => props.hovered && css`
    background: rgba(65, 131, 215, 0.07);
  `};
`;

style.Actions = styled.div`
  position: absolute;
  top: -32px;
  right: 8px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15), 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 4px;
  border-radius: 2px;
  background: white;
`;

style.Title = styled.div`
  padding: 4px 0;
  margin: 0 64px;
  font: 15px Roboto-Bold;
  margin-bottom: 20px;
`;

style.Content = styled.div`
`;

export default style;
