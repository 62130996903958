import React, { useState } from 'react';

import { Loader } from '@components/loader';
import { ListItem } from '@components/menu/listItem';
import { useFetch } from './useFetch';

import s from './lawList.style';

interface LawListProps {
  documentId: number;
  articleId: string;
  onSelect(filters): void;
}

export const LawList: React.FC<LawListProps> = (props) => {
  const { items, isLoading } = useFetch({ documentId: props.documentId, articleId: props.articleId });
  const [stateLoading, setLoading] = useState(false);

  const handleClick = (conditions) => () => {
    setLoading(true);
    props.onSelect(conditions);
  };

  const renderLoader = () => (
    <s.Loader>
      <Loader size={33} />
    </s.Loader>
  );

  const renderItems = () => (
    <s.List>
      {items.map((item) => <ListItem key={item.title} title={item.title} onClick={handleClick(item.conditions)} />)}
    </s.List>
  );

  const renderContent = () => {
    if (isLoading || stateLoading) {
      return renderLoader();
    }

    return renderItems();

  };

  return (
    <s.Root>
      {renderContent()}
    </s.Root>
  );
};

export default LawList;
